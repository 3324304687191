




















































import marked from 'marked';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Project from '@/classes/project';

@Component
export default class ProjectDetails extends Vue {
  @Prop({ required: true }) readonly project!: Project;

  private buttonBelow = false;

  private getImgUrl(fileName: string) {
    const images = require.context('@/assets/images/', true);
    return images(`./${fileName}`);
  }

  private toHtml(markdown: string) {
    return marked(markdown);
  }

  private computeButtonPosition() {
    this.buttonBelow = ((this.$refs.content as Element).clientHeight)
      >= ((this.$refs.thumbnail as Element).clientHeight);
  }

  mounted() {
    document.title = `Lancelot Blanchard | ${this.project.name}`;
    this.computeButtonPosition();
    window.addEventListener('resize', this.computeButtonPosition);
  }
}
